import _ from 'underscore'

import api from '@api'
import router from '@router'

import extensions from '@api/extension'
import sapi from '@api/service'

const endpoint = 'user'
const tenantAdmin = 'tenantadmin'
const customerAdmin = 'customeradmin'
const partner = 'partner'

/**
 * Module for the underlying context
 * Has to be initialized after the login process
 */
export default {
  namespaced: true,

  state: () => ({
    authed: false,
    loaded: false,
    user: null,
    token: '',
    tenant: null,
    tenants: [],
    customers: [],
    roles: [],
    changeTenant: false,
    activeExtensions: [],
    customerProperties: null,
    hideAllMenus: false,
    userConfig: {},
  }),

  mutations: {
    loaded(state, bool) {
      state.loaded = bool
    },
    user(state, user) {
      state.user = user
    },
    hideAllMenus(state, hideAllMenus) {
      state.hideAllMenus = hideAllMenus
    },
    token(state) {
      const tp = api.auth.tokenParsed
      if (tp && tp.realm_access && Array.isArray(tp.realm_access.roles)) {
        state.roles = tp.realm_access.roles
      }
      state.token = api.auth.token
      state.authed = !!(api.auth.token && state.tenant)
    },
    tenant(state, tenant) {
      state.tenant = tenant
      state.authed = !!(state.token && state.tenant)
      localStorage.setItem('context/tenant', tenant.id)
    },
    tenants(state, tenants) {
      state.tenants = _.sortBy(tenants, 'name')
    },
    customers(state, customers) {
      state.customers = customers
    },
    changeTenant(state, bool) {
      state.changeTenant = bool
    },
    activeExtensions(state, e) {
      state.activeExtensions = e
    },
    reset(state) {
      Object.keys(state).forEach(key => {
        state[key] = Array.isArray(state[key]) ? [] : null
      })
    },
    customerProperties(state, customerProperties) {
      state.customerProperties = customerProperties
    },
    userConfig(state, userConfig) {
      state.userConfig = userConfig
    },
  },

  actions: {
    async load({ state, commit, dispatch }, options) {
      commit('token')

      const userInfo = await api.auth.loadUserInfo()
      commit('user', userInfo)

      const tenants = await sapi.landlord.get(`${endpoint}/tenants`)
      commit('tenants', tenants.data)
      commit('tenant', lastTenant(state))

      await dispatch('loadCustomerData')

      dispatch('loadActiveExtensions')

      if (options?.checkProductActivation) {
        dispatch('redirectIfExtNotInitialized')
      }

      const userConfig = await api.userConfig.get()
      commit('userConfig', userConfig)
      dispatch('app/style/initPreferredDateFormat', null, { root: true })

      return tenants.data
    },
    async loadCustomerData({ state, commit }) {
      const customers = await sapi.landlord.get(`${endpoint}/customers`, {
        headers: {
          'X-es-tenant': state.tenant.id,
        },
      })
      commit('customers', customers.data)

      const customer = customers.data.find(c => c.id === state.tenant.customer)
      if (customer) {
        commit('customerProperties', customer.properties)
      }
    },
    async hideMenus({ commit }) {
      commit('hideAllMenus', true)
    },
    async loadActiveExtensions({ commit }) {
      if (this.getters['app/context/isAdmin']()) {
        const allExts = (await extensions.loadRegistrations()) || []
        commit('activeExtensions', allExts)
        return allExts
      } else {
        return []
      }
    },
    async switch({ state, commit, dispatch }, tenantId) {
      const tenant = state.tenants.find(e => {
        return e.id === tenantId
      })
      const change = !state.tenant || (tenant && tenant.id !== state.tenant.id)
      if (change) {
        commit('tenant', tenant)
        commit('app/nav/collapse', null, { root: true })
        await Promise.all([
          dispatch('dm/action/load', null, { root: true }),
          dispatch('dm/extensions/load', null, { root: true }),
          dispatch(
            'dm/config/load',
            {
              bundles: true,
              themes: false,
              logos: true,
              favicons: true,
              async: false,
            },
            { root: true }
          ),
        ]).finally(() => {
          dispatch('dm/processes/load', null, { root: true })
        })
      }
      return Promise.resolve(change)
    },
    async redirectIfExtNotInitialized({ state }) {
      try {
        const status = await api.productActivation.getStatusByCustomer(
          state.tenant.customer
        )
        if (status && status.productIdentifier) {
          const productInfo = await api.product.get(status.productIdentifier)
          const extensionNames = productInfo.extensionNames
          const regs = await api.extension.loadRegistrations()
          const installedExtIds = regs
            .filter(r => extensionNames.includes(r.extension.name))
            .map(r => r.id)
          const regStatus = await Promise.all(
            installedExtIds.map(async regId => {
              const status = await api.extension.getStatus(regId)
              const reg = regs.find(r => regId === r.id)
              return {
                status: status,
                regId,
                name: reg?.extension.name,
              }
            })
          )
          const activatedExtNames = regStatus
            .filter(s => s.status === 'activated')
            .map(s => s.name)
          if (activatedExtNames.length !== extensionNames.length) {
            await router.push({
              name: 'activation-wizard',
              query: { id: status.activationId },
            })
          }
        }
      } catch (e) {
        // ignore errors, use the default route
      }
    },
  },

  getters: {
    isAdmin: (state, getters) => () => {
      return getters.isCustomerAdmin() || getters.isTenantAdmin()
    },
    isCustomerAdmin: state => () => {
      return state.tenant && state.roles.includes(customerAdmin)
    },
    isTenantAdmin: state => () => {
      return (
        state.tenant &&
        state.roles.includes(`${tenantAdmin}-${state.tenant.id}`)
      )
    },
    isPartner: state => () => {
      return state.tenant && state.roles.includes(partner)
    },
    hasExperimentalAccess: state => () => {
      return state.tenant?.config?.experimentalExtensions || false
    },
    maxUploadSizeMB: state => () => {
      return state.tenant?.config?.maxUploadSizeMB
    },
    features: state => () => {
      return state.tenant?.features || ['']
    },
    tenantConfig: state => () => {
      return state.tenant?.config || {}
    },
    activeExtensions: state => () => {
      return state.activeExtensions || []
    },
    customerProperties: state => () => {
      return state.customerProperties
    },
  },
}

function lastTenant(state) {
  let last = localStorage.getItem('context/tenant')
  if (last && (last = state.tenants.find(t => t.id === last))) {
    if (last) {
      return last
    }
  }
  return state.tenants[0]
}
