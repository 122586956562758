import { setLabel } from './utils'

export default (model = {}) => {
  return setLabel(
    {
      ...{
        aggregation: '',
        datatype: '',
        defaultValue: '',
        description: {},
        label: {},
        mandatory: false,
        meaning: '',
        name: '',
        properties: {},
      },
      ...model,
    },
    'label'
  )
}
