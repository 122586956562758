export function getEnvVar(name) {
  try {
    return window.config[name] || process.env[name]
  } catch {
    return ''
  }
}

export const productUrls = Object.freeze({
  archivesaas: [
    'archive.easy.de',
    'archive.easy-software.com',
    'one-dm-archive-dev.easy02.proactcloud.de',
    'gemini-archive-dev.easy02.proactcloud.de',
    'laconia-archive-dev.easy02.proactcloud.de',
  ],
})
