<template>
  <router-view v-resize="resize" />
</template>

<script>
import { mapState } from 'vuex'

import { productUrls } from '@utils/env'

export default {
  name: 'App',
  data() {
    return {
      wasMobile: false,
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile
    },
    ...mapState({
      dark: state => state.app.theme.dark,
      favicon: state => state.dm.config.src.favicon,
    }),
  },
  watch: {
    favicon() {
      this.setFavIcon()
    },
  },
  created() {
    this.setFavIcon()
    this.render()
    this.$vuetify.theme.dark = this.dark
  },
  methods: {
    resize() {
      if (this.wasMobile !== this.mobile) {
        this.render()
      }
    },
    render() {
      if (!this.mobile) {
        this.$vuetify.goTo(0).then(() => {
          this.setOverflow()
        })
      } else {
        this.setOverflow()
      }
      this.wasMobile = this.mobile
    },
    setOverflow() {
      const root = document.documentElement
      if (root) {
        const val = this.mobile ? 'auto' : 'hidden'
        root.style.setProperty('overflow-y', val)
      }
    },
    setFavIcon() {
      const defaultFavicon = process.env.VUE_APP_BASE_PATH + 'favicon.ico?v=2'
      const archiveFavicon =
        process.env.VUE_APP_BASE_PATH + 'faviconArchive.ico?v=2'
      const currentURL = window.location.href

      this.updateFavicon(this.favicon || defaultFavicon)

      if (productUrls.archivesaas.some(url => currentURL.includes(url))) {
        this.updateFavicon(archiveFavicon)
      }
    },
    updateFavicon(newFaviconUrl) {
      const favicon = document.querySelector('link[rel="icon"]')
      favicon.setAttribute('href', newFaviconUrl)
    },
  },
}
</script>
