import api from '@api'

export default {
  namespaced: true,

  state: () => ({
    result: {},
    scrollPos: 0,
    lastQuery: null,
    pageSize: 0,
    hasMoreHits: true,
    pagination: {
      page: 1,
      itemsLength: 0,
      itemsPerPage: 10,
    },
    faceted: {
      model: {},
      types: [],
    },
    facetedFilter: true,
    searchableTypes: [],
    searchMask: {},
    displayedEntityID: undefined,
    tableState: {},
  }),

  mutations: {
    result(state, result) {
      state.result = result
    },
    scrollPos(state, pos) {
      state.scrollPos = pos
    },
    lastQuery(state, query) {
      state.lastQuery = query
    },
    hasMoreHits(state, hasMoreHits) {
      state.hasMoreHits = hasMoreHits
    },
    pageSize(state, pageSize) {
      state.pageSize = pageSize
    },
    pagination(state, options) {
      state.pagination.page = options.page
      state.pagination.itemsPerPage = options.itemsPerPage
      refreshPagination(state)
    },
    faceted(state, { key, value }) {
      state.faceted[key] = value
    },
    facetedFilter(state, facetedFilter) {
      state.facetedFilter = facetedFilter
    },
    searchableTypes(state, searchableTypes) {
      state.searchableTypes = searchableTypes
    },
    searchMask(state, value) {
      state.searchMask = value
    },
    displayedEntityID(state, id) {
      state.displayedEntityID = id
    },
    saveEntity(state, item) {
      const arr = state.result?.entities
      const att = 'system.foreignId'
      if (arr && item[att] && state.result?.types[item.type]) {
        let idx = arr.findIndex(e => e[att] === item[att])
        if (idx === -1 && item['system.version']) {
          const rootId = 'system.rootId'
          idx = arr.findIndex(
            e => e.id === item[rootId] || e[rootId] === item[rootId]
          )
        }
        if (idx > -1) {
          arr.splice(idx, 1, item)
        } else {
          addEntityInResult(state, item)
        }
      }
    },
    deleteEntity(state, item) {
      const arr = state.result?.entities
      const att = 'id'
      if (arr && item[att]) {
        const idx = arr.findIndex(e => e[att] === item[att])
        if (idx > -1) {
          arr.splice(idx, 1)
          refreshPagination(state)
        }
      }
    },
    tableState(state, options) {
      state.tableState = options
    },
  },

  actions: {
    async query({ commit, state }, query = {}) {
      try {
        commit('result', {})
        commit('lastQuery', query)
        commit('hasMoreHits', true)
        query.pageSize = state.pageSize
        const res = await api.search.search(query)
        if (!res.nextPageToken || res.entities.length < state.pageSize) {
          commit('hasMoreHits', false)
        }
        commit('result', res)
        return res
      } catch (err) {
        commit('result', {})
        throw err
      }
    },
    async queryTable({ commit, state, dispatch }, query = {}) {
      // load one more entity, then button next page will be enabled
      commit('pageSize', state.pagination.itemsPerPage + 1)
      commit('pagination', { ...state.pagination, page: 1 })
      await dispatch('query', query)
      commit('pageSize', state.pagination.itemsPerPage)
      commit('pagination', state.pagination)
    },
    async queryCard({ commit, dispatch }, query = {}) {
      commit('pageSize', 20)
      await dispatch('query', query)
    },
    async loadNext({ state, commit, dispatch }, count = 0) {
      try {
        const pageSize = count || state.pageSize
        const query = { ...state.lastQuery }
        query.pageSize = pageSize
        if (state.result.nextPageToken) {
          query.pageToken = state.result.nextPageToken
        }
        const res = await api.search.search(query)
        if (!res.nextPageToken || res.entities.length < pageSize) {
          commit('hasMoreHits', false)
        }
        if (res.entities.length) {
          const oldCount = res.entities.length
          res.entities = res.entities.filter(
            entity => !state.result.entities.some(e => e.id === entity.id)
          )
          const newCount = res.entities.length
          res.entities = state.result.entities.concat(res.entities)
          res.types = { ...res.types, ...state.result.types }
          commit('result', res)
          // check "newCount > 0" is added to avoid infinite loop
          if (newCount < oldCount && state.hasMoreHits && newCount > 0) {
            return await dispatch('loadNext', oldCount - newCount)
          }
          return res
        } else {
          return state.result
        }
      } catch (err) {
        throw err
      }
    },
    async updateOptions({ commit, state, dispatch }, options = {}) {
      await dispatch('sort', options)
      if (
        state.pagination.itemsPerPage &&
        options.itemsPerPage !== state.pagination.itemsPerPage
      ) {
        await dispatch('changeItemsPerPage', options.itemsPerPage)
      }
      if (
        options.page !== state.pagination.page &&
        state.hasMoreHits &&
        state.result.entities.length <
          options.page * state.pagination.itemsPerPage + 1
      ) {
        await dispatch('changePage', options.page)
      }
      commit('pagination', options)
      commit('tableState', options)
    },
    async sort({ commit, state, dispatch }, options = {}) {
      let sort = ''
      if (options.sortBy?.length) {
        sort = options.sortBy[0].replace(/\w*./, '')
        if (sort) {
          sort +=
            options.sortDesc[0] || options.sortDesc.length === 0
              ? ':desc'
              : ':asc'
        }
      }
      const resultSort = state.result?.sort?.length
        ? `${state.result.sort[0].field}:${state.result.sort[0].order}`
        : ''
      if (sort !== resultSort) {
        let sortIsPossible = true
        if (resultSort) {
          const attributes = state.result?.table?.attributes || []
          sortIsPossible = attributes.some(attr =>
            attr.id.includes(`.${state.result.sort[0].field}`)
          )
        }
        if (sortIsPossible || (!sortIsPossible && sort)) {
          const query = { ...state.lastQuery, sort }
          delete query.pageToken
          // load one more entity, then button next page will be enabled
          commit('pageSize', state.pagination.itemsPerPage + 1)
          await dispatch('query', query)
          commit('pageSize', state.pagination.itemsPerPage)
        }
      }
    },
    async changeItemsPerPage({ commit, state, dispatch }, itemsPerPage) {
      if (
        state.result.entities.length < itemsPerPage + 1 &&
        state.hasMoreHits
      ) {
        await dispatch(
          'loadNext',
          itemsPerPage - state.result.entities.length + 1
        )
      }
      commit('pageSize', itemsPerPage)
    },
    async changePage({ state, dispatch }, page) {
      if (
        page * state.pagination.itemsPerPage +
          1 -
          state.result.entities.length !==
        state.pagination.itemsPerPage
      ) {
        await dispatch(
          'loadNext',
          page * state.pagination.itemsPerPage +
            1 -
            state.result.entities.length
        )
      } else {
        await dispatch('loadNext')
      }
    },
    async loadTypes({ commit, state }, forceLoad) {
      if (state.searchableTypes.length <= 0 || forceLoad) {
        const types = await api.entity.types()
        commit('searchableTypes', types)
      }
      return state.searchableTypes
    },
    async deleteEntity({ state, commit, dispatch }, item) {
      commit('deleteEntity', item)
      if (state.hasMoreHits) {
        await dispatch('loadNext', 1)
      }
    },
  },
}

function refreshPagination(state) {
  const count = state.result?.entities?.length || 0
  state.pagination.pageCount = Math.ceil(count / state.pagination.itemsPerPage)
  state.pagination.pageStart =
    (state.pagination.page - 1) * state.pagination.itemsPerPage
  state.pagination.pageStop =
    state.pagination.page * state.pagination.itemsPerPage
  if (state.pagination.pageStop > count) {
    state.pagination.pageStop = count
  }
  if (!state.hasMoreHits) {
    state.pagination.itemsLength = count
  } else {
    state.pagination.itemsLength = `${count - 1}+`
  }
}

function addEntityInResult(state, item) {
  const arr = state.result?.entities
  if (state.result?.sort[0]) {
    if (state.result.sort[0].field === 'created') {
      if (state.result.sort[0].order === 'desc') {
        arr.unshift(item)
        refreshPagination(state)
      } else if (!state.hasMoreHits) {
        arr.push(item)
        refreshPagination(state)
      }
    } else {
      state.result.entities = []
    }
  }
}
